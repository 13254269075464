<template>
  <div
    v-if="visible"
    class="fixed z-40 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      v-if="this.$store.getters.settings.license.active"
    >
      <div
        @click.prevent="hide"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        v-if="!loading"
        class="
            inline-block
            bg-gray-300
            rounded-3xl
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            my-24
            align-middle max-w-5xl w-full
          "
      >
        <div class="flex flex-col justify-center items-center">
          <div class="bg-ssr-side_bar-background flex w-full justify-between">
            <div class="md:text-2xl text-white font-bold py-2 pl-6">
              {{ $t("accounts.money.title") }}
            </div>

            <div
              @click.prevent="hide"
              class="
                  rounded-full
                  cursor-pointer
                  bg-ssr-side_bar-button_hover
                  text-white text-xs
                  my-2
                  mr-6
                  w-28
                  flex
                  justify-center
                  items-center
                  font-bold
                "
            >
              {{ $t("accounts.cancel") }}
            </div>
          </div>
          <div
            class="
                flex flex-col
                w-full
                md:w-1/3
                items-center
                justify-center
                bg-gray-300
              "
          >
            <label class="font-bold mt-6">{{ $t("accounts.username") }}</label>
            <input
              v-model="selectedAdmin.email"
              type="text"
              class="
                bg-white
                rounded-full
                w-full
                shadow-lg
                p-1
                pl-3
                focus:outline-none
                text-center
              "
              :disabled="selectedAdmin.id"
              :class="{
                'opacity-70 cursor-not-allowed': selectedAdmin.id,
              }"
            />
            <label class="font-bold mt-6">{{
              $t("accounts.money.current")
            }}</label>
            <input
              v-model="selectedAdmin.money"
              type="text"
              class="
                bg-white
                rounded-full
                w-full
                shadow-lg
                p-1
                pl-3
                focus:outline-none
                text-center
              "
              :disabled="selectedAdmin.id"
              :class="{
                'opacity-70 cursor-not-allowed': selectedAdmin.id,
              }"
            />
            <div class="flex my-6 w-full">
              <input
                v-model="deposit_amount"
                class="bg-white
                rounded-full
                w-full
                shadow-lg
                p-1
                pl-3
                focus:outline-none
                text-center flex-1"
                type="number"
              />
              <button
                @click.prevent="deposit()"
                class="bg-green-500 text-white rounded-full w-full py-1 px-3 flex-1"
              >
                {{ $t("accounts.money.deposit") }}
              </button>
            </div>
            <div class="flex mb-6 w-full">
              <input
                v-model="withdraw_amount"
                class="bg-white
                rounded-full
                w-full
                shadow-lg
                p-1
                pl-3
                focus:outline-none
                text-center flex-1"
                type="number"
              />
              <button
                @click.prevent="withdraw()"
                class="bg-red-500 text-white rounded-full w-full py-1 px-3 flex-1"
              >
                {{ $t("accounts.money.withdraw") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
            inline-block
            align-bottom
            bg-gray-300
            rounded-3xl
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            my-24
            sm:my-8 sm:align-middle
          "
        v-if="loading"
      >
        <div class="flex flex-col items-center">
          <img
            class="inline-block h-48 w-48"
            src="@/assets/loading.gif"
            alt="Loading"
          />
        </div>
      </div>
    </div>
    <ExpiredWindow v-else />
  </div>
</template>

<script>
import moment, { months } from "moment";
import { hasPrivilege } from "../../global_constants.js";
import { mapGetters } from "vuex";
import axios from "axios";
import NumericalSpinner from "../formElements/NumericalSpinner.vue";
// import Datepicker from "vue3-date-time-picker";
import LoadingOverlay from "../modals/LoadingOverlay.vue";
import ExpiredWindow from "../ExpiredWindow.vue";
import ToggleButton from "../formElements/ToggleButton.vue";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  components: {
    NumericalSpinner,
    ExpiredWindow,
    ToggleButton,
  },
  name: "AdminMoney",
  props: ["visible", "selectedAdmin"],
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  data() {
    return {
      loading: false,
      deposit_amount: 0,
      withdraw_amount: 0,
      validated: true,
    };
  },

  methods: {
    async deposit() {
      this.loading = true;
      const response = await axios.post(
        `admin/bills/${this.selectedAdmin.id}/bill`,
        {
          amount: this.deposit_amount,
          action: "deposit",
        }
      );
      if (response.status == 200) {
        this.loading = false;
        this.$emit("updated");
      } else if (response.status == 210) {
        this.loading = false;
        this.error_message = response.data.error;
      }
    },
    async withdraw() {
      this.loading = true;
      const response = await axios.post(
        `admin/bills/${this.selectedAdmin.id}/bill`,
        {
          amount: this.withdraw_amount,
          action: "withdraw",
        }
      );
      if (response.status == 200) {
        this.loading = false;
        this.$emit("updated");
      } else if (response.status == 210) {
        this.loading = false;
        this.error_message = response.data.error;
      }
    },
    hide() {
      this.$emit("hide-details");
    },
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
  },
};
</script>
