<template>
  <div class="main-content flex-1 relative bg-transparent">
    <loading-overlay :visible="loading" />
    <div
      v-if="this.$store.getters.settings.license.active"
      class="flex justify-center w-full"
    >
      <div
        class="
          bg-gray-300
          rounded-xl
          w-full
          m-6
          flex flex-col
          mb-12
          pb-12
          px-4
          md:px-20
        "
      >
        <div class="flex flex-col items-center mt-6">
          <img
            v-if="currentImageUrl"
            class="rounded-full h-32 w-32 mx-5"
            :src="currentImageUrl"
            alt="Person Image"
          />
          <img
            v-if="!currentImageUrl"
            class="rounded-full h-32 w-32 mx-5"
            src="@/assets/person.png"
            alt="Person Image"
          />
          <label
            for="image"
            class="bg-blue-400 rounded-2xl py-1 px-4 mt-2 cursor-pointer"
            >{{ $t("admins.change") }}</label
          >
          <input
            id="image"
            type="file"
            accept="image/*"
            ref="imageFile"
            @change="selectImage"
            class="hidden"
          />
          <div class="grid grid-cols-2 lg:w-1/2 mt-6 gap-6">
            <div>
              <div class="font-bold">{{ $t("admins.first_name") }}</div>
              <input
                v-model="admin.first_name"
                type="text"
                @input="validate"
                :class="{ 'shadow-error': errors.first_name }"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                "
              />
            </div>
            <div>
              <div class="font-bold">{{ $t("admins.last_name") }}</div>
              <input
                v-model="admin.last_name"
                type="text"
                @input="validate"
                :class="{ 'shadow-error': errors.last_name }"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                "
              />
            </div>

            <div>
              <div class="font-bold">{{ $t("admins.email") }}</div>
              <input
                v-model="admin.email"
                oninput="this.value=this.value.replace(/[^a-zA-Z0-9_.@-\s]/g,'');"
                @input="validate"
                type="text"
                :class="{ 'shadow-error': errors.email }"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                "
              />
            </div>
            <div>
              <div class="font-bold">{{ $t("admins.password") }}</div>
              <input
                v-model="admin.password"
                @input="validate"
                :placeholder="admin.id ? 'No change' : ''"
                :class="{ 'shadow-error': errors.password && !admin.id }"
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                "
              />
            </div>
            <div class="col-span-2">
              <div class="font-bold">{{ $t("admins.phone") }}</div>
              <input
                v-model="admin.phone"
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                "
              />
            </div>
            <div class="col-span-2">
              <div class="font-bold">{{ $t("admins.allowed_nases") }}</div>
              <MultiSelect
                v-model="this.admin.allowed_nases"
                :mode="'tags'"
                :placeholder="'NONE'"
                :options="nases"
                :classes="{
                  container:
                    'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded bg-white rounded-3xl text-base leading-snug outline-none',
                  tag:
                    'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded rounded-full mr-1 mb-1 flex items-center whitespace-nowrap',
                }"
              />
            </div>
            <div class="col-span-2">
              <div class="font-bold">{{ $t("nases.allowed_profiles") }}</div>
              <MultiSelect
                v-model="this.admin.allowed_profiles"
                :mode="'tags'"
                :placeholder="'NONE'"
                :options="profiles"
                :classes="{
                  container:
                    'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded bg-white rounded-3xl text-base leading-snug outline-none',
                  tag:
                    'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded rounded-full mr-1 mb-1 flex items-center whitespace-nowrap',
                }"
              />
            </div>
            <div class="col-span-2" v-if="admin.type == 'reseller'">
              <div class="font-bold">
                {{ $t("admins.prefix") }}
              </div>
              <input
                v-model="admin.prefix"
                placeholder="EX: MO_"
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                "
              />
            </div>
            <div
              class="col-span-2"
              v-if="
                admin.type == 'reseller' &&
                  hasPrivilege('accounting_admins_manual_money')
              "
            >
              <div class="font-bold">
                {{ $t("admins.money") + " " + this.settings.currency }}
              </div>
              <input
                v-model="admin.money"
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                "
              />
            </div>
            <div class="col-span-2">
              <div
                class="text-red-600 mt-0.5"
                :class="{ hidden: !errors.first_name }"
              >
                *{{ errors.first_name }}
              </div>
              <div
                class="text-red-600 mt-0.5"
                :class="{ hidden: !errors.last_name }"
              >
                *{{ errors.last_name }}
              </div>
              <div
                class="text-red-600 mt-0.5"
                :class="{ hidden: !errors.email }"
              >
                *{{ errors.email }}
              </div>
              <!-- <div
                class="text-red-600 mt-0.5"
                :class="{ hidden: !errors.password && !this.admin.id }"
              >
                *{{ errors.password }}
              </div> -->
            </div>
          </div>
        </div>

        <!-- <div
          v-if="admin.id != user.data.id"
          class="flex flex-col w-full items-start"
        >
          <label class="font-bold mt-9">{{ $t("admins.privileges") }}</label>
          <TreeSelect
            v-if="!loading"
            :options="options"
            :value-consists-of="'LEAF_PRIORITY'"
            :multiple="true"
            :load-options="loadOptions"
            v-model="admin.privileges"
          />
        </div> -->
        <div
          class="flex flex-col w-full items-start"
          v-if="user.data.id != this.$route.params.id"
        >
          <label class="font-bold mt-9">{{ $t("admins.privileges") }}</label>
          <div
            class="bg-privileges-background rounded-3xl flex w-full mb-3"
            v-for="privilege in privileges"
            v-bind:key="privilege.id"
          >
            <div
              class="
                bg-privileges-tag
                w-1/5
                rounded-l-3xl
                text-center
                py-3
                text-white
                flex
                justify-center
                items-center
              "
            >
              {{ privilege.label }}
            </div>
            <div
              class="
                w-4/5
                text-center
                grid grid-cols-1
                md:grid-cols-2
                lg:grid-cols-4
                space-x-2
                text-sm
                mx-6
              "
            >
              <div
                v-for="child in privilege.children"
                v-bind:key="child.id"
                class="
                  bg-privileges-child
                  rounded-full
                  m-2
                  h-auto py-1
                  shadow-md
                  flex
                  justify-center
                  items-center
                  cursor-pointer
                "
                :class="{
                  'bg-privileges-child-selected text-white shadow-blue': admin.privileges.find(
                    (x) => x == child.id
                  ),
                }"
                @click.prevent="
                  admin.privileges.find((x) => x == child.id)
                    ? admin.privileges.splice(
                        admin.privileges.indexOf(child.id),
                        1
                      )
                    : admin.privileges.push(child.id)
                "
              >
                {{ child.label }}
              </div>
            </div>
          </div>
          <div class="flex justify-around items-center w-full">
            <div
              class="
                rounded-full
                bg-green-500
                text-white text-center
                py-1
                px-3
                cursor-pointer
              "
              @click.prevent="selectAll()"
            >
              {{ $t("admins.enable_all") }}
            </div>
            <div
              class="
                rounded-full
                bg-red-500
                text-white text-center
                py-1
                px-3
                cursor-pointer
              "
              @click.prevent="admin.privileges = []"
            >
              {{ $t("admins.disable_all") }}
            </div>
          </div>
        </div>
        <div class="text-red-500 text-center">
          {{ error_message }}
        </div>
        <div class="flex flex-col items-center mt-48 md:mt-9">
          <button
            :disabled="!validated"
            @click.prevent="store"
            class="
              w-5/6
              md:w-1/5
              bg-green-500
              text-white
              rounded-full
              py-1
              px-3
            "
            :class="{
              ' cursor-not-allowed opacity-50': !validated,
            }"
          >
            {{ this.admin.id ? $t("admins.save") : $t("admins.add") }}
          </button>
        </div>
      </div>
    </div>
    <ExpiredWindow v-else />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import NumericalSpinner from "../components/formElements/NumericalSpinner.vue";
import ToggleButton from "../components/formElements/ToggleButton.vue";
import Dropdown from "../components/formElements/Dropdown.vue";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import TreeSelect from "vue3-treeselect";
import { LOAD_CHILDREN_OPTIONS } from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import { hasPrivilege } from "../global_constants";
import MultiSelect from "@vueform/multiselect";
import ExpiredWindow from "../components/ExpiredWindow.vue";
export default {
  components: {
    NumericalSpinner,
    ToggleButton,
    Dropdown,
    LoadingOverlay,
    TreeSelect,
    MultiSelect,
    ExpiredWindow,
  },
  name: "AddAdmin",
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  created() {},
  data() {
    return {
      error: "",
      message: "",
      error_message: "",
      currentImageUrl: null,
      loading: false,
      admin: {
        first_name: "",
        last_name: "",
        phone: "",
        profile_image: "",
        email: "",
        password: "",
        privileges: [],
        allowed_nases: [],
        allowed_profiles: [],
        type: "admin",
        money: "-1",
        prefix: "",
      },
      profiles: [],
      nases: [],
      privileges: [],
      options: [
        {
          id: 0,
          label: "All",
          isDefaultExpanded: true,
          children: null,
        },
      ],
      errors: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
      },
      validated: false,
    };
  },
  inject: {
    server_url: {
      from: "server_url",
    },
  },
  async created() {
    this.loading = true;
    await this.fetchNases();
    await this.fetchProfiles();
    if (this.$route.params.id) {
      await this.fetchAdmin();
      if (this.admin.profile_image) {
        this.currentImageUrl = this.server_url + this.admin.profile_image;
      }
      this.validate();
    } else {
      var temp = [];
      this.nases.forEach((element) => {
        temp.push(element.value);
      });
      this.admin.allowed_nases = temp;
      temp = [];
      this.profiles.forEach((element) => {
        temp.push(element.value);
      });
      this.admin.allowed_profiles = temp;
    }
    const response = await axios.get("resellers/privileges");
    this.privileges = response.data;
    this.loading = false;
    this.admin.type = "reseller";
    if (this.admin.money == -1) this.admin.money = "0";
  },
  methods: {
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    selectImage() {
      this.admin.profile_image = this.$refs.imageFile.files.item(0);
      this.currentImageUrl = URL.createObjectURL(this.admin.profile_image);
    },
    async fetchAdmin() {
      this.loading = true;
      const response = await axios.get(`admins/${this.$route.params.id}`);
      if (response.status == 200) {
        this.admin = response.data.data;
        var temp = [];
        this.admin.allowed_nases.forEach((element) => {
          temp.push(element.id);
        });
        this.admin.allowed_nases = temp;
        temp = [];
        this.admin.allowed_profiles.forEach((element) => {
          temp.push(element.id);
        });
        this.admin.allowed_profiles = temp;
      }
      this.loading = false;
    },
    async fetchNases() {
      const response = await axios.get("nases?itr=true&srows=10000000");
      response.data.data.forEach((element) => {
        this.nases.push({ value: element.id, label: element.shortname });
      });
    },
    async fetchProfiles() {
      const response = await axios.get("profiles?itr=true&rows=10000000");
      response.data.data.forEach((element) => {
        this.profiles.push({ value: element.id, label: element.name });
      });
    },
    async store() {
      this.loading = true;
      if (this.$route.params.id) {
        var data = new FormData();
        data.append("profile_image", this.admin.profile_image);
        data.append("first_name", this.admin.first_name);
        data.append("last_name", this.admin.last_name);
        data.append("phone", this.admin.phone);
        data.append("email", this.admin.email);
        data.append("password", this.admin.password);
        data.append("type", this.admin.type);
        data.append("money", this.admin.money);
        data.append("prefix", this.admin.prefix);
        for (let i = 0; i < this.admin.privileges.length; i++) {
          data.append("privileges[]", this.admin.privileges[i]);
        }
        for (let i = 0; i < this.admin.allowed_nases.length; i++) {
          data.append("allowed_nases[]", this.admin.allowed_nases[i]);
        }
        for (let i = 0; i < this.admin.allowed_profiles.length; i++) {
          data.append("allowed_profiles[]", this.admin.allowed_profiles[i]);
        }
        var response = await axios.post(
          `/admins/${this.$route.params.id}`,
          data,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
      } else {
        var data = new FormData();
        data.append("profile_image", this.admin.profile_image);
        data.append("first_name", this.admin.first_name);
        data.append("last_name", this.admin.last_name);
        data.append("phone", this.admin.phone);
        data.append("email", this.admin.email);
        data.append("password", this.admin.password);
        data.append("type", this.admin.type);
        data.append("money", this.admin.money);
        data.append("prefix", this.admin.prefix);
        for (let i = 0; i < this.admin.privileges.length; i++) {
          data.append("privileges[]", this.admin.privileges[i]);
        }
        for (let i = 0; i < this.admin.allowed_nases.length; i++) {
          data.append("allowed_nases[]", this.admin.allowed_nases[i]);
        }
        for (let i = 0; i < this.admin.allowed_profiles.length; i++) {
          data.append("allowed_profiles[]", this.admin.allowed_profiles[i]);
        }
        var response = await axios.post("/admins", data, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }

      if (response.status == 200) {
        this.loading = false;
        this.$router.push({
          path: "/resellers",
          query: { success: "success" },
        });
      } else if (response.status == 210) {
        this.loading = false;
        this.errors.email = this.$t("errors.admins.username_taken");
        this.validated = false;
        window.scrollTo(0, 0);
      } else if (response.status == 211) {
        this.error_message = this.$t(`errors.license.${response.data.error}`);
        this.validated = false;
        this.loading = false;
      }
    },
    // async loadOptions({ action, parentNode, callback }) {
    //   this.loading = true;
    //   if (action === LOAD_CHILDREN_OPTIONS) {
    //     switch (parentNode.id) {
    //       case 0:
    //         const response = await axios.get("admins/privileges");
    //         parentNode.children = response.data;
    //         callback();
    //         break;
    //       default:
    //         break;
    //     }
    //   }
    //   this.loading = false;
    // },
    validate() {
      this.validated = true;
      this.errors.email = null;
      this.errors.password = null;
      this.errors.first_name = null;
      this.errors.last_name = null;
      if (this.admin.email.length < 3) {
        this.validated = false;
        this.errors.email = this.$t("errors.admins.username_3");
      }
      if (this.admin.first_name.length < 1) {
        this.validated = false;
        this.errors.first_name = this.$t("errors.admins.first_name");
      }
      if (this.admin.last_name.length < 1) {
        this.validated = false;
        this.errors.last_name = this.$t("errors.admins.last_name");
      }
      // if (!this.admin.password) this.admin.password = "1";
      // if (
      //   this.admin.password.length < 3 &&
      //   !this.admin.id &&
      //   !this.$route.params.id
      // ) {
      //   this.validated = false;
      //   this.errors.password = this.$t("errors.admins.password_3");
      // }
    },
    selectAll() {
      this.admin.privileges = [];
      this.privileges.forEach((element) => {
        element.children.forEach((privilege) => {
          this.admin.privileges.push(privilege.id);
        });
      });
    },
  },
};
</script>
